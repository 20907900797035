import { withModalWindow } from 'bv-hocs';
import FormContainer from './components/form_container';

export default withModalWindow(
  'javascript.twofa.navigation_title.mobile_sms_verification',
  {
    className: 'two-factor-modal',
    contentClassName: 'register-phone-number-wrapper',
  },
)(FormContainer);
