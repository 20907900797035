import { getJSON, postJSON } from 'bv-fetch';

export const getMobileNumber = () => (
  getJSON('/bv_api/twofactorauth/get_mobile_number').catch(() => {})
);

export const validateMobileNumberService = (number) => (
  postJSON('/bv_api/twofactorauth/validate_mobile_number', number).catch(() => {})
);

export const send2FACode = (number) => (
  postJSON('/bv_api/twofactorauth/enable_send_code', { mobileNumber: number }).catch(() => {})
);
